import request from '@/utils/request'

// ----------------------------用户管理--------------------------------------

// 部门列表 
export function loadUserData(data) {
    return request({
        url: '/set_user',
        method: 'get',
        params: data,
    })
}
// 添加用户
export function addUser(data) {
    return request({
        url: '/save_user',
        method: 'post',
        data,
    })
}

// 添加用户时获取的信息
export function getAddIndex(data) {
    return request({
        url: '/get_addIndex',
        method: 'get',
        params: data,
    })
}

// 批量添加用户
export function addUserAll(data) {
    return request({
        url: '/save_user_all',
        method: 'post',
        data,
    })
}

// 编辑页面-获取用户信息
export function userInfo(data) {
    return request({
        url: '/user_edit_open',
        method: 'get',
        params: data,
    })
}
// 编辑页面-提交用户信息
export function adminUpdateUser(data) {
    return request({
        url: '/admin_update_user',
        method: 'post',
        data,
    })
}

// 获取档案信息
export function loadArchivesInfo(data) {
    return request({
        url: '/set_archives_field',
        method: 'get',
        params: data,
    })
}

// 自定义-获取数据
export function setArchivesField(data) {
    return request({
        url: '/set_archives_field',
        method: 'get',
        params: data,
    })
}

// 自定义-添加/修改字段
export function saveaArchivesField(data) {
    return request({
        url: '/save_archives_field',
        method: 'post',
        data,
    })
}

// 自定义-删除字段
export function deleteArchivesField(data) {
    return request({
        url: '/delete_archives_field',
        method: 'get',
        params: data,
    })
}


// 自定义-删除用户
export function delUser(data) {
    return request({
        url: '/user_delete',
        method: 'get',
        params: data,
    })
}


// 批量导入用户

// 自定义-添加/修改字段
export function importUser(data) {
    return request({
        url: '/import_user',
        method: 'post',
        data,
    })
}

// 批量设置用户权限
export function userAccess(data) {
    return request({
        url: '/user_access',
        method: 'post',
        data,
    })
}
// 切换到测评用户
export function switchoverUser(data) {
    return request({
        url: '/switchover_user',
        method: 'get',
        params: data,
    })
}

// ------------------------量表管理----------------------------------

// 编辑页面-获取用户信息
export function loadMeasureList(data) {
    return request({
        url: '/list_measure?',
        method: 'get',
        params: data,
    })
}

// 页面-分组获取所有量表
export function getMeasureByClassify(data) {
    return request({
        url: '/all_measure_classify?',
        method: 'get',
        params: data,
    })
}

// 列表页面-获取量表分类列表
export function getMeasureClassifyList(data) {
    return request({
        url: '/list_measure_classify?',
        method: 'get',
        params: data,
    })
}

// 列表页面-删除量表分类列表
export function delMeasureClassifyList(data) {
    return request({
        url: '/delete_measure_classify?',
        method: 'get',
        params: data,
    })
}

// 列表页面-添加量表分类列表
export function addMeasureClassifyList(data) {
    return request({
        url: '/created_measure_classify',
        method: 'post',
        data,
    })
}

// 列表页面-添加量表分类列表
export function updateMeasureClassifyList(data) {
    return request({
        url: '/update_measure_classify',
        method: 'post',
        data,
    })
}

// 列表页面-获取量表分类列表
export function getMeasureGroupList(data) {
    return request({
        url: '/list_measure_group?',
        method: 'get',
        params: data,
    })
}

// 列表页面-获取量表分类列表
export function getMeasureGroupDetail(data) {
    return request({
        url: '/get_measure_group',
        method: 'get',
        params: data,
    })
}

// 列表页面-添加量表分类
export function addMeasureGroup(data) {
    return request({
        url: '/created_measure_group',
        method: 'post',
        data
    })
}

// 列表页面-添加量表分类
export function addMeasure(data) {
    return request({
        url: '/created_measure',
        method: 'post',
        data
    })
}

// 列表页面-更新量表组分类
export function updateMeasureGroup(data) {
    return request({
        url: '/update_measure_group',
        method: 'post',
        data
    })
}

// 列表页面-删除量表分组列表
export function delMeasureGroupList(data) {
    return request({
        url: '/delete_measure_group?',
        method: 'get',
        params: data,
    })
}

// 列表页面-添加量表分类列表
export function updateMeasureGroupList(data) {
    return request({
        url: '/update_measure_group',
        method: 'post',
        data,
    })
}

// 量表管理-修改量表信息

export function updateMeasureInfo(data) {
    return request({
        url: '/update_measure',
        method: 'post',
        data,
    })
}
// 量表管理-获取量表详情
export function getMeasureInfo(data) {
    return request({
        url: '/get_measure_by_id',
        method: 'get',
        params: data,
    })
}
// 量表管理-删除量表
export function deleteMeasure(data) {
    return request({
        url: '/delete_measure',
        method: 'get',
        params: data,
    })
}

// 量表管理 -  二维码量表 标示

export function getMeasureCode(data) {
    return request({
        url: '/measure_code',
        method: 'get',
        params: data,
    })
}

export function getMeasureQrCodeFromGroup(data) {
    return request({
        url: '/generate_measure_qrcode_group',
        method: 'get',
        params: data,
    })
}


// ------------------------权限管理页面----------------------------

// 获取部门下面的管理员列表
export function loadListDepart(data) {
    return request({
        url: '/list_depart_admin',
        method: 'get',
        params: data,
    })
}

// 权限角色列表
export function loadListPermit(data) {
    return request({
        url: '/list_permit',
        method: 'get',
        params: data,
    })
}

// 编辑角色权限
export function getPermit(data) {
    return request({
        url: '/get_permit',
        method: 'get',
        params: data,
    })
}

// 权限树
export function getPermitTree(data) {
    return request({
        url: '/permit_tree',
        method: 'get',
        params: data,
    })
}

// 管理权限-添加权限
export function addPermit(data) {
    return request({
        url: '/created_permit',
        method: 'post',
        data,
    })
}
// 管理权限-修改权限
export function updatePermit(data) {
    return request({
        url: '/update_permit',
        method: 'post',
        data,
    })
}

// 删除权限
export function delPermit(data) {
    return request({
        url: '/delete_permit',
        method: 'get',
        params: data,
    })
}

// 获取部门详情
export function getDepartment(data) {
    return request({
        url: '/get_department?',
        method: 'get',
        params: data,
    })
}
// 获取管理详情
export function getAadminInfo(data) {
    return request({
        url: '/get_admin_by_id',
        method: 'get',
        params: data,
    })
}

// 管理权限-删除管理员
export function delAadmin(data) {
    return request({
        url: '/delete_admin',
        method: 'post',
        data,
    })
}
// 管理权限-添加部门
export function createdDepartment(data) {
    return request({
        url: '/created_department',
        method: 'post',
        data,
    })
}
// 管理权限-修改部门信息
export function updateDepartment(data) {
    return request({
        url: '/update_department',
        method: 'post',
        data,
    })
}
// 管理权限-修改管理员
export function updateAdmin(data) {
    return request({
        url: '/update_admin',
        method: 'post',
        data,
    })
}

// 权限管理-删除部门
export function deleteDepartment(data) {
    return request({
        url: '/delete_department',
        method: 'get',
        params: data,
    })
}









// --------------晤谈检查---------------------

// 晤谈检查-list列表
export function getInterviewList(data) {
    return request({
        url: '/list_interview_report',
        method: 'get',
        params: data,
    })
}

// 晤谈检查-获取分类列表
export function getInterviewClassifyList(data) {
    return request({
        url: '/list_interview_classify',
        method: 'get',
        params: data,
    })
}

// 晤谈检查-创建分类
export function createdInterviewClassify(data) {
    return request({
        url: '/created_interview_classify',
        method: 'post',
        data,
    })
}

// 晤谈检查-更新分类
export function updateInterviewClassify(data) {
    return request({
        url: '/update_interview_classify',
        method: 'post',
        data,
    })
}

// 晤谈检查-删除分类
export function deleteInterviewClassify(data) {
    return request({
        url: '/delete_interview_classify',
        method: 'get',
        params: data,
    })
}
// 晤谈检查-获取晤谈题目详情
export function getInterviewDetail(data) {
    return request({
        url: '/get_interview_detail',
        method: 'get',
        params: data,
    })
}

// 晤谈检查-添加晤谈题目
export function createdInterviewDetail(data) {
    return request({
        url: '/created_interview_detail',
        method: 'post',
        data,
    })
}

// 晤谈检查-删除晤谈题目
export function deleteInterviewDetail(data) {
    return request({
        url: '/delete_interview_detail',
        method: 'get',
        params: data,
    })
}

// 晤谈检查-更新晤谈题目
export function updateInterviewDetail(data) {
    return request({
        url: '/update_interview_detail',
        method: 'post',
        data,
    })
}

// 晤谈检查--分配
// get_interview_user
export function getInterviewUser(data) {
    return request({
        url: '/get_interview_user',
        method: 'get',
        params: data,
    })
}
// 晤谈检查--分配查询
// get_interview_users
export function getInterviewUsers(data) {
    return request({
        url: '/get_interview_users',
        method: 'get',
        params: data,
    })
}
// 晤谈检查--移除分配
export function dltInterviewUser(data) {
    return request({
        url: '/dlt_interview_user',
        method: 'get',
        params: data,
    })
}